import { useState, useEffect } from 'react';

const useParentRouteMonitor = () => {
  const [currentLocation, setCurrentLocation] = useState({
    fullUrl: window.location.href,
    pathname: window.location.pathname,
    hash: window.location.hash,
    search: window.location.search
  });

  useEffect(() => {
    let lastHref = window.location.href;

    const checkForUrlChange = () => {
      const currentHref = window.location.href;
      
      if (currentHref !== lastHref) {
        lastHref = currentHref;
        setCurrentLocation({
          fullUrl: window.location.href,
          pathname: window.location.pathname,
          hash: window.location.hash,
          search: window.location.search
        });
      }
    };

    const intervalId = setInterval(checkForUrlChange, 100);
    
    const pushStateOriginal = window.history.pushState;
    const replaceStateOriginal = window.history.replaceState;

    window.history.pushState = function() {
      pushStateOriginal.apply(this, arguments);
      checkForUrlChange();
    };

    window.history.replaceState = function() {
      replaceStateOriginal.apply(this, arguments);
      checkForUrlChange();
    };

    const handleNavigationEvents = () => checkForUrlChange();
    
    window.addEventListener('popstate', handleNavigationEvents);
    window.addEventListener('hashchange', handleNavigationEvents);
    
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        checkForUrlChange();
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => {
      clearInterval(intervalId);
      window.history.pushState = pushStateOriginal;
      window.history.replaceState = replaceStateOriginal;
      window.removeEventListener('popstate', handleNavigationEvents);
      window.removeEventListener('hashchange', handleNavigationEvents);
      observer.disconnect();
    };
  }, []);

  return currentLocation;
};

export default useParentRouteMonitor;