import React, { useEffect } from "react";
import { ClientData, FloorplanType } from "./Types";
import { useNavigate, useParams } from "react-router-dom";
import useGTM from "./hooks/useGTM";
import { formatNumber } from "./helpers/formatNumber";

const FloorplanDetails = ({
  data,
  setData,
  clientData,
}: {
  data: FloorplanType | null;
  setData: (id: string) => void;
  clientData?: ClientData;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const pushEventToDataLayer = useGTM();

  useEffect(() => {
    if (id) setData(id);
  }, [id, setData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <img
          src={data?.image}
          alt=""
          style={{ height: "320px", objectFit: "contain", padding: "5px" }}
        />
        <div
          style={{
            padding: "5px 15px",
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div
            style={{ fontSize: "20px", fontWeight: "600", color: "#201f1e" }}
          >
            {data?.name}
          </div>
          <div className="w-floorplan-details" style={{ fontSize: "14px" }}>
            {data?.bedsCount
              ? data.bedsCount + " Bedroom(s) / "
              : "0 Bedroom(s) / "}
            {data?.bathsCount} Bath(s)
          </div>
          <div className="w-floorplan-details" style={{ fontSize: "14px" }}>
            ${formatNumber(data?.price.min)} - ${formatNumber(data?.price.max)}
          </div>
          <div className="w-floorplan-details" style={{ fontSize: "14px" }}>
            {formatNumber(data?.size)} {data?.sizeUnits}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div
              className="w-primary-button"
              style={{
                border: "1px solid var(--w-primary-color)",
                background: "var(--w-primary-color)" || "#3898ec",
                color: "#fff",
                fontSize: "15px",
              }}
              onClick={() => {
                if (
                  clientData?.ui.videoWidget.bookGuidedTour.isActive &&
                  clientData?.ui.videoWidget.bookGuidedTour.url
                ) {
                  window.open(
                    clientData?.ui.videoWidget.bookGuidedTour.url,
                    "_blank"
                  );
                  pushEventToDataLayer(clientData?.id + " show_book_tour_external", {
                    event_category: "button",
                    event_action: "click",
                    event_label: "booktourexternal_button",
                    page_path: `/video/floorplans/floorplan/${data?.name}/book`,
                    page_client_id: clientData?.id,
                  });
                } else {
                  pushEventToDataLayer(clientData?.id + " show_book_inperson_tour", {
                    event_category: "button",
                    event_action: "click",
                    event_label: "bookinpersontour_button",
                    page_path: `/video/floorplans/floorplan/${data?.name}/book`,
                    page_client_id: clientData?.id,
                  });
                  navigate(`/video/floorplans/floorplan/${data?.name}/book`);
                }
              }}
            >
              { clientData?.ui.videoWidget.bookGuidedTour.name
                ? clientData.ui.videoWidget.bookGuidedTour.name
                : "Book Guided Tour"}
            </div>
            <div
              className="w-primary-button"
              style={{
                border: "1px solid var(--w-primary-color)",
                background: "transparent",
                color: "var(--w-primary-color)" || "#3898ec",
                fontSize: "15px",
              }}
              onClick={() => {
                navigate(
                  `/video/floorplans/floorplan/${data?.name}/ask-question`
                );
                pushEventToDataLayer(
                  clientData?.id + " ask_question_floorplan",
                  {
                    event_category: "button",
                    event_action: "click",
                    event_label: "askquestion_button",
                    page_path: `/video/floorplans/floorplan/${data?.name}/ask-question`,
                    page_client_id: clientData?.id,
                  }
                );
              }}
            >
              Ask a Question
            </div>
          </div>
          {/* <button
            style={{
              marginTop: "15px",
              color: "var(--w-primary-color)" || "rgb(100 116 139)",
              fontSize: "15px",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={toggleToAskQuestion}
          >
            Ask a question
          </button> */}
        </div>
      </div>
    </>
  );
};

export default FloorplanDetails;
