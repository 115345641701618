import React, { useEffect, useRef } from "react";
import { ClientData } from "./Types.ts";

const GetInTouch = ({
  clientData,
  isLg,
}: {
  clientData?: ClientData;
  isLg: boolean;
}) => {
  const formContainerRef = useRef<HTMLDivElement>(null);
  const formId = clientData?.ui?.videoWidget?.contactForm?.contactFormId;
  const formUrl = clientData?.ui?.videoWidget?.formUrl;

  useEffect(() => {
    if (!formId || !formContainerRef.current || !formUrl) return;

    // First, add the formsappId attribute to the container div
    if (formContainerRef.current) {
      formContainerRef.current.setAttribute('formsappId', formId);
    }

    // Create and load the script
    const script = document.createElement("script");
    script.src = "https://forms.app/static/embed.js";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;

    // Configure the form when the script loads
    script.onload = () => {
      // Check if the global formsapp function is available (added by the script)
      if (typeof window.formsapp === 'function' && formContainerRef.current) {
        // Initialize the form with proper parameters
        new window.formsapp(
          formId,
          'standard', // or 'fullscreen' based on your preference
          {
            'width': '100%',
            'height': '500px',
            'borderWidth': '0', // Remove border
            'borderRadius': '0',
            'backgroundColor': 'transparent'
          },
          formUrl
        );
        
      }
    };

    document.body.appendChild(script);

    return () => {
      // Clean up script and event listeners on unmount
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [formId, formUrl]);

  return (
    <div
      className="w-menu-container"
      style={{ paddingBottom: !isLg ? "64px" : "unset" }}
    >
      <div
        style={{
          marginTop: "15px",
          marginBottom: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#201f1e",
            padding: "10px",
            textAlign: "center",
          }}
        >
          Contact Us
        </div>
        <div
          ref={formContainerRef}
          id={`form-container-${formId}`} // Unique ID for the container
          style={{
            overflowY: "auto",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1"
          }}
        />
      </div>
    </div>
  );
};

export default GetInTouch;