import { useEffect } from "react";
import { ClientData } from "./Types";
import { useLocation, useNavigate } from "react-router-dom";
import useGTM from "./hooks/useGTM";

interface ChatWidgetProps {
  clientData?: ClientData;
}

const ChatWidget = ({ clientData }: ChatWidgetProps) => {
  const navigate = useNavigate();
  const pushEventToDataLayer = useGTM();
  const location = useLocation();
  const widgetPosition = clientData?.ui.position;
  const chatAppId = clientData?.ui.chatWidget.intercomId;
  const brand = clientData?.ui.chatWidget.brand;

  useEffect(() => {
    window.intercomSettings = {
      app_id: chatAppId || "",
      brand: brand,
      hide_default_launcher: true,
      alignment: widgetPosition,
      background_color: clientData?.ui.colors.background.primary,
      action_color: clientData?.ui.colors.background.primary,
    };

    const loadIntercom = () => {
      if (!window.Intercom) {
        const intercomScript = document.createElement("script");
        intercomScript.type = "text/javascript";
        intercomScript.async = true;
        intercomScript.src = `https://widget.intercom.io/widget/${chatAppId}`;
        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode?.insertBefore(intercomScript, firstScript);

        intercomScript.onload = () => {
          if (window.Intercom) {
            window.Intercom("reattach_activator");
            window.Intercom("update", window.intercomSettings);
            setupIntercomListeners();
            handleIntercomVisibility();
          }
        };
      } else {
        setupIntercomListeners();
        handleIntercomVisibility();
      }
    };

    const setupIntercomListeners = () => {
      // Prevent adding multiple listeners
      if (window.Intercom && !window.__intercomOnHideListenerAdded) {
        window.Intercom("onHide", () => {
          pushEventToDataLayer(clientData?.id + " chat_closed", {
            event_category: "button",
            event_action: "click",
            event_label: "chat_button",
            page_path: "/",
            page_client_id: clientData?.id,
          });

          if (location.pathname === "/chat") {
            navigate("/");
          }
        });
        window.__intercomOnHideListenerAdded = true;
      }
    };

    const handleIntercomVisibility = () => {
      if (location.pathname === "/chat") {
        window.Intercom && window.Intercom("show");
      } else {
        window.Intercom && window.Intercom("hide");
      }
    };

    loadIntercom();

    return () => {
      if (window.Intercom) {
        window.Intercom("hide");
      }
    };
  }, [
    location.pathname,
    navigate,
    chatAppId,
    brand,
    widgetPosition,
    clientData,
    pushEventToDataLayer,
  ]);

  return null;
};

export default ChatWidget;
