import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import TagManager from "react-gtm-module";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  zIndex: {
    modal: 10000,
  },
});

const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayerName: "dataLayerSMP",
  };
  TagManager.initialize(tagManagerArgs);
};

initializeGTM();

const scriptTag = document.currentScript;
const clientId = scriptTag?.dataset.clientId;

ReactDOM.createRoot(document.getElementById("widget")!).render(
  <ThemeProvider theme={theme}>
    <App clientId={clientId} />
  </ThemeProvider>
);
