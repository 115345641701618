import React, { useEffect, useState } from "react";
import Button from "./Button";
import { useLocation, useNavigate } from "react-router-dom";
import useGTM from "./hooks/useGTM";
import { ClientData } from "./Types";

const MenuButtons = ({
  isActiveMenuButtons,
  setIsActiveMenuButtons,
  setIsShownMenu,
  isLg,
  clientData,
  bookSelfTour,
}: {
  isActiveMenuButtons: boolean;
  setIsActiveMenuButtons: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShownMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isLg: boolean;
  clientData?: ClientData;
  bookSelfTour?: {
    isActive: boolean;
    name?: string;
    url: string;
  };
}) => {
  const [activeButton, setActiveButton] = useState<
    | "floorplans"
    | "book-tour"
    | "tour-3d"
    | "get-in-touch"
    | "book-guided-tour"
    | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pushEventToDataLayer = useGTM();

  useEffect(() => {
    if (!isActiveMenuButtons) {
      setActiveButton(null);
    }
  }, [isActiveMenuButtons]);

  const toggleActiveButton = (value: string) => {
    if (activeButton === value) {
      setIsShownMenu(false);
      setActiveButton(value);
    } else {
      if (
        value === "floorplans" ||
        value === "book-tour" ||
        value === "tour-3d" ||
        value === "get-in-touch" ||
        value === "book-guided-tour"
      ) {
        setActiveButton(value);
        setIsActiveMenuButtons(true);
        setIsShownMenu(false);
      }
    }
  };

  useEffect(() => {
    const pathArr = location.pathname.split("/");
    const lastSegment = pathArr[pathArr.length - 1];
    toggleActiveButton(lastSegment);
  }, [location]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(1, minmax(0px, 1fr))",
        gap: isLg && isActiveMenuButtons ? "4px" : "8px",
      }}
    >
      {clientData?.property.floorplans.isActive && (
        <Button
          text={"Check out Floor Plans"}
          isActive={activeButton === "floorplans"}
          first={true}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            toggleActiveButton("floorplans");
            navigate("/video/floorplans");
            pushEventToDataLayer(clientData?.id + " show_all_floorplans", {
              event_category: "button",
              event_action: "click",
              event_label: "floorplans_button",
              page_path: "/video/floorplans",
              page_client_id: clientData?.id,
            });
          }}
        />
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${
            (!isLg && clientData?.property.tour3d.isActive) ||
            (isLg &&
              !isActiveMenuButtons &&
              clientData?.property.tour3d.isActive)
              ? "2"
              : "1"
          }, minmax(0px, 1fr))`,
          gap: isLg && isActiveMenuButtons ? "4px" : "8px",
        }}
      >
        {clientData?.property.tour3d.isActive && (
          <Button
            text={"Take a 3D Tour"}
            isActive={activeButton === "tour-3d"}
            first={false}
            isBurgerMenu={isLg && isActiveMenuButtons}
            handleClick={() => {
              toggleActiveButton("tour-3d");
              navigate("/video/tour-3d");
              pushEventToDataLayer(clientData?.id + " show_tour_3d", {
                event_category: "button",
                event_action: "click",
                event_label: "tour3d_button",
                page_path: "/video/tour-3d",
                page_client_id: clientData?.id,
              });
            }}
          />
        )}
        {clientData?.ui.videoWidget.contactForm.isActive && 
        <Button
          text={"Get in Touch"}
          isActive={activeButton === "get-in-touch"}
          first={false}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            toggleActiveButton("get-in-touch");
            navigate("/video/get-in-touch");
            pushEventToDataLayer(clientData?.id + " show_get_in_touch", {
              event_category: "button",
              event_action: "click",
              event_label: "getintouch_button",
              page_path: "/video/get-in-touch",
              page_client_id: clientData?.id,
            });
          }}
        />
}
      </div>

      {clientData?.ui.videoWidget.bookGuidedTour.isActive && (
        <Button
          text={clientData?.ui.videoWidget.bookGuidedTour?.name || "Book Guided Tour"}
          first={false}
          isActive={activeButton === "book-guided-tour"}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            if (
              clientData?.ui.videoWidget.bookGuidedTour &&
              clientData.ui.videoWidget.bookGuidedTour.url
            ) {
              window.open(
                clientData?.ui.videoWidget.bookGuidedTour.url,
                "_blank"
              );
              pushEventToDataLayer(clientData?.id + " show_book_tour_external", {
                event_category: "button",
                event_action: "click",
                event_label: "booktourexternal_button",
                page_path: "/video/book-guided-tour",
                page_client_id: clientData?.id,
              });
            } else {
              toggleActiveButton("book-guided-tour");
              pushEventToDataLayer(clientData?.id + " show_book_inperson_tour", {
                event_category: "button",
                event_action: "click",
                event_label: "bookinpersontour_button",
                page_path: "/video/book-guided-tour",
                page_client_id: clientData?.id,
              });
              navigate("/video/book-guided-tour");
            }
          }}
        />
      )}
      {bookSelfTour?.isActive && bookSelfTour.url.length > 0 && (
        <Button
          text={bookSelfTour?.name || "Book Self-Guided Tour"}
          first={false}
          isActive={activeButton === "book-tour"}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            window.open(bookSelfTour.url, "_blank");
            pushEventToDataLayer(clientData?.id + " show_book_self_tour", {
              event_category: "button",
              event_action: "click",
              event_label: "bookselftour_button",
              page_path: "/video/book-tour",
              page_client_id: clientData?.id,
            });
          }}
        />
      )}
    </div>
  );
};

export default MenuButtons;
