import React, { useEffect, useRef } from "react";
import { ClientData } from "./Types.ts";
import { useParams } from "react-router-dom";

const AskQuestion = ({
  clientData,
  apartmentName,
  setData,
  isLg
}: {
  clientData?: ClientData;
  apartmentName?: string;
  setData?: (id: string) => void;
  isLg: boolean;
}) => {
  const { id } = useParams();
  const formContainerRef = useRef<HTMLDivElement>(null);
  const formId = clientData?.ui.videoWidget.askQuestionForm.askQuestionFormId;
  const paramId = clientData?.ui.videoWidget.askQuestionForm.askQuestionFormParameterId || "";
  const paramValue = encodeURIComponent(`I'm interested in ${apartmentName} floor plan.`);
  let formUrl = clientData?.ui.videoWidget.formUrl;
  
  useEffect(() => {
    if (id && setData) setData(id);
  }, [id, setData]);

  useEffect(() => {
    if (!formId || !formContainerRef.current || !formUrl) return;

    // Add the formsappId attribute to the container div
    if (formContainerRef.current) {
      formContainerRef.current.setAttribute('formsappId', formId);
    }
    

    // Create and load the script
    const script = document.createElement("script");
    script.src = "https://forms.app/static/embed.js";
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;

    // Configure the form when the script loads
    script.onload = () => {
      if (typeof window.formsapp === 'function' && formContainerRef.current) {
       
        // Initialize the form with proper parameters and custom styling
        new window.formsapp(
          formId,
          'standard',
          {
            'width': '100%',
            'height': '500px',
            'borderWidth': '0',
            'borderRadius': '0',
            'backgroundColor': 'transparent',
            'answers': { [paramId]: `${paramValue}` } 
          },
          formUrl
        );
    
      }
    };

    document.body.appendChild(script);

    return () => {
      // Clean up script, style, and event listeners on unmount
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [formId, paramId, apartmentName, formUrl, paramValue]);

  return (
    <div
      className="w-menu-container"
      style={{ paddingBottom: !isLg ? "64px" : "unset" }}
    >
      <div
        style={{
          marginTop: "15px",
          marginBottom: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#201f1e",
            padding: "10px",
            textAlign: "center",
          }}
        >
            Ask a Question about {apartmentName} Floor Plan
          </div>
          <div
            ref={formContainerRef}
            id={`form-container-${formId}`}
            style={{
              overflowY: "auto",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "1"
            }}
          />
        </div>
      </div>
  );
};

export default AskQuestion;