import React, { useCallback, useEffect, useRef, useState } from "react";
import MenuControls from "./MenuControls";
import ControlButton from "./ControlButton";
import { modalStyle, paragraphStyle, backdropFilterStyle } from "./Styles";
import InstagramFeed from "./InstagramFeed";
import VideoComponent from "./VideoComponent";
import { ClientData } from "./Types";
import useGTM from "./hooks/useGTM.ts";
import MenuButtons from "./MenuButtons";
import RadientSvg from "./RadientSvg.tsx";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { PageData } from "./Types";
import Button from "./Button.tsx";
import { ToastContainer } from "react-toastify";

const WidgetLayout = ({
  clientData,
  children,
  floorplanId,
}: {
  clientData?: ClientData;
  children?: string | JSX.Element | JSX.Element[];
  floorplanId?: string;
}) => {
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isShownMenu, setIsShownMenu] = useState(false);
  const [shared, setShared] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const fillerRef = useRef<HTMLDivElement>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isShownInstagram, setIsShownInstagram] = useState(false);
  const [isShownAmenities, setIsShownAmenities] = useState(false);

  const [isActiveMenuButtons, setIsActiveMenuButtons] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const matchBookTourinFloorplans = useMatch(
    "/video/floorplans/floorplan/:id/book"
  );
  const matchAskQuestioninFloorplans = useMatch(
    "/video/floorplans/floorplan/:id/ask-question"
  );

  const matchFloorplansDetails = useMatch("/video/floorplans/floorplan/:id");

  const pushEventToDataLayer = useGTM();

  const isLg = windowSize.width >= 1024;
  const isMd = windowSize.width >= 768 && windowSize.width < 1024;
  const isSm = windowSize.width < 768;


  const modelUnit: PageData = {
    type: "MODELUNIT",
    videoUrl: clientData?.property.pages.find((el) => el.type === "MODELUNIT")
      ?.video,
    title: "Model Units",
    subtitle: clientData?.property.pages.find((el) => el.type === "MODELUNIT")
      ?.name,
    // buttons: [
    //   {
    //     text: "Explore Amenities",
    //     onClick: () => {
    //       handleChangeContent(modelUnitData, amenitiesData);
    //     },
    //   },
    // ],
    isActive:  clientData?.property.pages.find((el) => el.type === "MODELUNIT")?.isActive
  };

  const amenities: PageData = {
    type: "AMENITY",
    videoUrl: clientData?.property.pages.find((el) => el.type === "AMENITY")
      ?.video,
    title: "Amenities",
    subtitle: clientData?.property.pages.find((el) => el.type === "AMENITY")
      ?.name,
    additionalInfo: clientData?.property.pages.find(
      (el) => el.type === "AMENITY"
    )?.additionalInfo,
    desc: clientData?.property.pages.find((el) => el.type === "AMENITY")
      ?.legend,
    // buttons: [
    //   {
    //     text: "View Our Model Unit",
    //     onClick: () => {
    //       handleChangeContent(amenitiesData, modelUnitData);
    //     },
    //   },
    // ],
    isActive:  clientData?.property.pages.find((el) => el.type === "AMENITY")?.isActive
  };

  const initial: PageData = {
    type: "WELCOME",
    videoUrl:
      clientData?.property.pages.find((el) => el.type === "WELCOME")?.video ||
      clientData?.property.coverVideo,
    title: clientData?.property.name,
    subtitle:
      clientData?.property.location.city +
      ", " +
      clientData?.property.location.state,
    buttons: [
      modelUnit.isActive ?
      {
        text: "View Our Model Unit",
        onClick: () => {
          if(modelUnit.isActive)
          handleChangeContent(initial, modelUnit);
        },
      }: null,
      amenities.isActive ?
      {
        text: "Explore Amenities",
        onClick: () => {
          if(amenities.isActive)
          handleChangeContent(initial, amenities);
        },
      }: null,
    ].filter(Boolean) || [],
    isActive:  clientData?.property.pages.find((el) => el.type === "WELCOME")?.isActive
  };

  const [currentData, setCurrentData] = useState<PageData>(initial);
  const [history, setHistory] = useState<PageData[]>([]);

  const handleChangeContent = (previousData, nextData: PageData) => {
    setHistory((prev) => [...prev, previousData]);
    setCurrentData(nextData);
    pushEventToDataLayer(clientData?.id + ` show_${nextData.title?.toLowerCase().replace(/\s+/g, "_")}_video`, {
      event_category: "button",
      event_action: "click",
      event_label: "video_button",
      page_path: "/video",
      page_client_id: clientData?.id,
    });
    if (videoRef.current?.paused) {
      setIsVideoPaused(false);
    }
  };

  const handleRevertContent = () => {
    if (history.length > 0) {
      const previousContent = history[history.length - 1];
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      setCurrentData(previousContent);
    }
    if (videoRef.current?.paused) {
      setIsVideoPaused(false);
    }
  };

  useEffect(() => {
    if (location.pathname === "/video") {
      setIsActiveMenuButtons(false);
    } else {
      setIsActiveMenuButtons(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  //mute after first loop of the video
  useEffect(() => {
    const videoEl = videoRef.current;

    const handleVideoEnd = () => {
      if (videoEl) {
        if (videoEl.currentTime >= videoEl.duration - 0.5) {
          videoEl.muted = true;
        }
      }
    };

    if (videoEl) {
      videoEl.addEventListener("timeupdate", handleVideoEnd);
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("timeupdate", handleVideoEnd);
      }
    };
  }, []);

  //after first loop video is muted, when the view is changed, video should be unmuted
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  }, [currentData]);

  const handlePause = useCallback(() => {
    videoRef.current?.pause();
    setIsVideoPaused(true);
  }, []);

  const handlePlay = useCallback(() => {
    videoRef.current?.play().catch((error) => {
      console.log("Autoplay prevented", error);
    });
    setIsVideoPaused(false);
  }, []);

  useEffect(() => {
    if (isActiveMenuButtons) {
      handlePause();
    } else {
      handlePlay();
    }
  }, [isActiveMenuButtons, handlePlay, handlePause]);

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      currentVideoRef.addEventListener("timeupdate", updateProgress);
    }
    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, []);

  const handleToggleVideo = () => {
    videoRef.current?.paused ? handlePlay() : handlePause();
  };

  const handleFullScreen = () => {
    const el = modalRef.current;
    if (el) {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if ((el as any).mozRequestFullScreen) {
        (el as any).mozRequestFullScreen();
      } else if ((el as any).webkitRequestFullscreen) {
        (el as any).webkitRequestFullscreen();
      } else if ((el as any).msRequestFullscreen) {
        (el as any).msRequestFullscreen();
      }
    }
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else if ((document as any).mozFullScreenElement) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitFullscreenElement) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msFullscreenElement) {
      (document as any).msExitFullscreen();
    }
  };

  const handleFullScreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const toggleMute = () => {
    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      currentVideoRef.muted = !currentVideoRef.muted;
      if (currentVideoRef.paused) {
        currentVideoRef.play();
        setIsVideoPaused(false);
      }
    }
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const progressPercent = (currentTime / duration) * 100;
      setProgress(progressPercent);
    }
  };

  const toggleShowMenu = () => {
    setIsShownMenu(!isShownMenu);
    if (isShownInstagram) {
      setIsShownInstagram(false);
    }
    if (isShownAmenities) {
      setIsShownAmenities(false);
    }
  };

  const toggleShared = () => {
    setShared(!shared);
  };

  const toggleShowInstagram = () => {
    if (!isShownInstagram) {
      pushEventToDataLayer(clientData?.id + " show_instagram", {
        event_category: "button",
        event_action: "click",
        event_label: "instagram_button",
        page_client_id: clientData?.id,
      });
    }

    const videoEl = videoRef.current;
    if (videoEl) {
      if (!isShownInstagram) {
        handlePause();
        videoEl.muted = true;
      } else {
        if (!isActiveMenuButtons) {
          handlePlay();
          // videoEl.muted = false;
        }
      }
    }
    setIsShownInstagram(!isShownInstagram);
    if (isShownMenu) {
      setIsShownMenu(false);
    }
    if (isShownAmenities) {
      setIsShownAmenities(false);
    }
  };

  const toggleShowAmenities = () => {
    if (!isShownAmenities) {
      pushEventToDataLayer(clientData?.id + " show_amenity_list", {
        event_category: "button",
        event_action: "click",
        event_label: "amenity_button",
        page_client_id: clientData?.id,
      });
    }
    setIsShownAmenities(!isShownAmenities);
    if (isShownMenu) {
      setIsShownMenu(false);
    }
    if (isShownInstagram) {
      setIsShownInstagram(false);
    }
  };

  const handleFillerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (fillerRef.current && videoRef.current) {
      const fillerRect = fillerRef.current.getBoundingClientRect();
      const clickPosition = event.clientX - fillerRect.left;
      const newTime =
        (clickPosition / fillerRect.width) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
      setProgress((newTime / videoRef.current.duration) * 100);
    }
  };

  const handleCloseModal = () => {
    handleExitFullScreen();
    navigate("/");
    pushEventToDataLayer(clientData?.id + " widget_closed", {
      event_category: "button",
      event_action: "click",
      event_label: "circle_button",
      page_path: "/",
      page_client_id: clientData?.id,
    });
  };

  const handleIsActiveMenuButtons = (value: boolean) => {
    setIsActiveMenuButtons(value);
  };

  //if it's in subcomponents of floorplans component, toggle between them
  //if it's in floorplans or any other component in menu, toggle to main menu
  const handleGoBack = () => {
    if (matchBookTourinFloorplans || matchAskQuestioninFloorplans) {
      navigate(`/video/floorplans/floorplan/${floorplanId}`);
    } else if (matchFloorplansDetails) {
      navigate("/video/floorplans");
    } else {
      handleIsActiveMenuButtons(false);
      navigate("/video");
    }
  };

  if (!currentData) {
    return;
  }

  return (
    <div
      ref={modalRef}
      id="modal"
      style={{
        ...modalStyle,
        ...{
          height: !isLg ? "95%" : "90%",
          left:
            clientData?.ui.position === "left"
              ? !isLg
                ? "20px"
                : "35px"
              : "unset",
          right:
            clientData?.ui.position === "right"
              ? !isLg
                ? "20px"
                : "35px"
              : "unset",
          width: !isLg ? "85%" : "65%",
          pointerEvents: "all",
          opacity: "1",
          transform: "scale(1)",
          transformOrigin: `${clientData?.ui.position} bottom`,
          backgroundColor: "rgb(var(--w-secondary-color))",
          transition: "all 0.2s linear 0s",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 20,
          position: "absolute",
          top: 0,
          color: "#FFFFFF",
          pointerEvents: "none",
          width: !isLg
            ? "100%"
            : location.pathname !== "/video"
            ? "100%"
            : "65%",
        }}
      >
        {/* filler for video  */}
        <div
          ref={fillerRef}
          onClick={handleFillerClick}
          style={{
            width: "100%",
            height: "8px",
            pointerEvents: "auto",
            transition: "height 0.2s",
            cursor: "pointer",
            display: isActiveMenuButtons ? "none" : "block",
          }}
          onMouseEnter={() => {
            if (fillerRef.current) {
              fillerRef.current.style.height = "16px";
            }
          }}
          onMouseLeave={() => {
            if (fillerRef.current) {
              fillerRef.current.style.height = "8px";
            }
          }}
        >
          <div
            style={{
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
              height: "100%",
              pointerEvents: "none",
              width: `${progress}%`,
              backgroundColor: "var(--w-primary-color)",
            }}
          ></div>
        </div>

        {/* menu for video controls */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pointerEvents: "none",
          }}
        >
          <MenuControls
            isLg={isLg}
            isMd={isMd}
            isFullScreen={isFullscreen}
            handleFullScreen={handleFullScreen}
            handleExitFullScreen={handleExitFullScreen}
            toggleMute={toggleMute}
            isActiveMenuButtons={isActiveMenuButtons}
            goBack={
              isActiveMenuButtons
                ? handleGoBack
                : currentData.type === "WELCOME"
                ? undefined
                : handleRevertContent
            }
            muted={videoRef?.current?.muted}
            closeModal={handleCloseModal}
            toggleShared={toggleShared}
            toggleShowInstagram={toggleShowInstagram}
            toggleShowMenu={toggleShowMenu}
            isInstagramEnabled={clientData?.ui.videoWidget.isInstagramEnabled}
          />
        </div>
      </div>

      {isActiveMenuButtons && (
        <div
          style={{
            width: isLg
              ? !isActiveMenuButtons
                ? "35%"
                : isActiveMenuButtons
                ? "180px"
                : "100%"
              : "100%",
            zIndex:
              isLg && !isActiveMenuButtons ? "auto" : isShownMenu ? "30" : "-1",
            pointerEvents: "none",
            bottom: isLg && isActiveMenuButtons ? "unset" : 0,
            top: isLg && isActiveMenuButtons ? "55px" : "unset",
            right: isLg && isActiveMenuButtons ? "50px" : "unset",
            paddingBottom: isSm || isMd ? "64px" : 0,
            display: "flex",
            opacity:
              isLg && !isActiveMenuButtons ? "1" : isShownMenu ? "1" : "0",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: isLg && !isActiveMenuButtons ? "static" : "absolute",
            borderLeft:
              isLg && !isActiveMenuButtons
                ? `1px solid var(--w-tertiary-color)`
                : "",
            background:
              isLg && isActiveMenuButtons
                ? "#fff"
                : "rgba(var(--w-secondary-color), 0.8)",
            borderTopLeftRadius: isLg && !isActiveMenuButtons ? "0" : "12px",
            borderTopRightRadius: isLg && !isActiveMenuButtons ? "0" : "12px",
            borderBottomRightRadius: isLg && isActiveMenuButtons ? "12px" : "0",
            borderBottomLeftRadius: isLg && isActiveMenuButtons ? "12px" : "0",
            transition: "all 0.3s ease",
            boxShadow:
              isLg && isActiveMenuButtons
                ? "rgba(0, 0, 0, 0.4) 0px 5px 10px"
                : !isLg
                ? "rgba(0, 0, 0, 0.5) 0px 3px 20px"
                : "none",
          }}
        >
          <div
            style={{
              ...(!isLg && isActiveMenuButtons && backdropFilterStyle),
              height: "100%",
              width: isLg && !isActiveMenuButtons ? "35%" : "100%",
              position: "absolute",
              top: 0,
              borderTopLeftRadius: !isLg ? "12px" : 0,
              borderTopRightRadius: !isLg ? "12px" : 0,
              borderBottomLeftRadius: isLg ? "12px" : 0,
              borderBottomRightRadius: isLg ? "12px" : 0,
              transition: "all 0.4s ease",
            }}
          />
          <div
            style={{
              height: "100%",
              width: "100%",
              pointerEvents: "auto",
              borderTopLeftRadius: !isLg ? "12px" : "0",
              borderTopRightRadius: !isLg ? "12px" : "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-center",
            }}
          >
            <div
              style={{
                display: isActiveMenuButtons ? "none" : "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                color: "#6B7280",
                justifyContent: "flex-end",
              }}
            >
              {(isSm || isMd || (isLg && !isActiveMenuButtons)) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    paddingTop: "20px",
                    paddingRight: isActiveMenuButtons ? "8px" : "20px",
                  }}
                >
                  {/* close widget button */}
                  <ControlButton
                    style={{
                      border: "none",
                      background: "none",
                      padding: "10px",
                      display: "flex",
                      transition: "background-color 0.15s ease",
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    onClick={
                      isLg && !isActiveMenuButtons
                        ? handleCloseModal
                        : toggleShowMenu
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.7"
                      stroke="currentColor"
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#000",
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </ControlButton>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* left half - components */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <div
          style={{
            width: !isLg
              ? "100%"
              : location.pathname !== "/video"
              ? "100%"
              : "65%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#fff",
            position: "relative",
          }}
        >
          <VideoComponent
            isLg={isLg}
            isSm={isSm}
            isShownMenu={isShownMenu}
            currentData={currentData}
            handleToggleVideo={handleToggleVideo}
            videoRef={videoRef}
            isVideoPaused={isVideoPaused}
            isShownInstagram={isShownInstagram}
            isActiveMenuButtons={isActiveMenuButtons}
            toggleShowAmenities={toggleShowAmenities}
            isShownAmenities={isShownAmenities}
            thumbnail={clientData?.property.coverImage}
          />

          {/* instagram  */}
          <div
            style={{
              // transform: isShownInstagram
              //   ? "translateY(0%)"
              //   : "translateY(100%)",
              opacity: isShownInstagram ? "1" : "0",
              background: "rgb(var(--w-secondary-color), 0.9)" ?? "#FFFFFF",
              position: "absolute",
              zIndex: isShownInstagram ? 20 : -1,
              width: "100%",
              height: "80%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              bottom: !isLg ? "64px" : 0,
              left: 0,
              // paddingBottom: !isLg ? 0 : "32px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "all 0.2s ease",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 20px",
            }}
          >
            {isShownInstagram && (
              <InstagramFeed
                clientData={clientData}
                toggleShowInstagram={toggleShowInstagram}
                isMd={isMd}
                isLg={isLg}
              />
            )}
          </div>
          <div
            style={{
              overflowY: "auto",
              width: "100%",
              display: isActiveMenuButtons ? "flex" : "none",
              flexDirection: "column",
              height: "100%",
              maxHeight: "100%",
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "50px",
                padding: "7px 15px",
                background: "#fff",
                borderBottom: "1px solid var(--w-tertiary-color)",
                position: "sticky",
                top: 0,
                left: 0,
                right: 0,
              }}
            />
            {children}
          </div>
          <ToastContainer
            position="bottom-right"
            style={{
              margin: "5px",
              marginBottom: isLg ? "unset" : isMd ? "40px" : "70px",
              width: isLg || isMd ? "auto" : "70%",
              justifySelf: "end",
            }}
          />
          {/* share option */}
          {/* <div
            style={{
              // transform: shared ? "translateY(0%)" : "translateY(100%)",
              opacity: shared ? "1" : "0",
              background: "var(--w-secondary-color)" || "#FFFFFF",
              position: "absolute",
              zIndex: shared ? 50 : -1,
              width: "100%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              bottom: 0,
              paddingBottom: !isLg ? "80px" : "32px",
              left: 0,
              paddingTop: "4px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: clientData?.ui.colors.text.heading ?? "#000000",
              transition: "all 0.4s ease",
              borderTop:
                isShownInstagram || (!isLg && isShownMenu)
                  ? `1px solid var(--w-tertiary-color)`
                  : "",
            }}
          >
            <ShareContainer toggleShow={toggleShared} />
          </div> */}
        </div>

        {/* welcome and buttons */}
        <div
          style={{
            width: isLg
              ? !isActiveMenuButtons
                ? "35%"
                : isActiveMenuButtons
                ? "180px"
                : "100%"
              : "100%",
            zIndex:
              isLg && !isActiveMenuButtons ? "auto" : isShownMenu ? "30" : "-1",
            pointerEvents: "none",
            bottom: isLg && isActiveMenuButtons ? "unset" : 0,
            top: isLg && isActiveMenuButtons ? "55px" : "unset",
            right: isLg && isActiveMenuButtons ? "50px" : "unset",
            paddingBottom: isSm || isMd ? "64px" : 0,
            display: "flex",
            opacity:
              isLg && !isActiveMenuButtons ? "1" : isShownMenu ? "1" : "0",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: isLg && !isActiveMenuButtons ? "static" : "absolute",
            borderLeft:
              isLg && !isActiveMenuButtons
                ? `1px solid var(--w-tertiary-color)`
                : "",
            background:
              isLg && isActiveMenuButtons
                ? "#fff"
                : "rgba(var(--w-secondary-color), 0.8)",
            borderTopLeftRadius: isLg && !isActiveMenuButtons ? "0" : "12px",
            borderTopRightRadius: isLg && !isActiveMenuButtons ? "0" : "12px",
            borderBottomRightRadius: isLg && isActiveMenuButtons ? "12px" : "0",
            borderBottomLeftRadius: isLg && isActiveMenuButtons ? "12px" : "0",
            transition: "all 0.3s ease",
            boxShadow:
              isLg && isActiveMenuButtons
                ? "rgba(0, 0, 0, 0.4) 0px 5px 10px"
                : !isLg
                ? "rgba(0, 0, 0, 0.5) 0px 3px 20px"
                : "none",
          }}
        >
          <div
            style={{
              ...(!isLg && isActiveMenuButtons && backdropFilterStyle),
              height: "100%",
              width: isLg && !isActiveMenuButtons ? "35%" : "100%",
              position: "absolute",
              top: 0,
              borderTopLeftRadius: !isLg ? "12px" : 0,
              borderTopRightRadius: !isLg ? "12px" : 0,
              borderBottomLeftRadius: isLg ? "12px" : 0,
              borderBottomRightRadius: isLg ? "12px" : 0,
              transition: "all 0.4s ease",
            }}
          />
          <div
            style={{
              height: "100%",
              width: "100%",
              pointerEvents: "auto",
              borderTopLeftRadius: !isLg ? "12px" : "0",
              borderTopRightRadius: !isLg ? "12px" : "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                color: "#6B7280",
                justifyContent: "flex-end",
              }}
            >
              {(isSm || isMd || (isLg && !isActiveMenuButtons)) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    paddingTop: "20px",
                    paddingRight: isActiveMenuButtons ? "8px" : "20px",
                  }}
                >
                  {/* share button on menu */}
                  {/*
                <ControlButton
                  style={{
                    border: "none",
                    background: "none",
                    padding: "10px",
                    display: "flex",
                    transition: "background-color 0.15s ease",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                  onClick={toggleShared}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#000",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                    ></path>
                  </svg>
                </ControlButton> */}
                  {/* close widget button */}
                  <ControlButton
                    style={{
                      border: "none",
                      background: "none",
                      padding: "10px",
                      display: "flex",
                      transition: "background-color 0.15s ease",
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    onClick={
                      isLg && !isActiveMenuButtons
                        ? handleCloseModal
                        : toggleShowMenu
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.7"
                      stroke="currentColor"
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#000",
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </ControlButton>
                </div>
              )}
            </div>
            {isLg && !isActiveMenuButtons && (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <RadientSvg />
              </div>
            )}
            {/* welcome part */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                paddingBottom: isLg ? "12px" : "24px",
                justifyContent: "center",
                paddingLeft: !isLg ? "10%" : "8px",
                paddingRight: !isLg ? "10%" : "8px",
              }}
            >
              <>
                <div
                  style={{
                    color: clientData?.ui.colors.text.heading ?? "#000",
                    fontSize: isLg ? "30px" : "22px",
                    textAlign: "center",
                    fontWeight: 600,
                    display:
                      isSm || isMd || (isLg && !isActiveMenuButtons)
                        ? "flex"
                        : "none",
                    flexDirection: "column",
                    zIndex: 2,
                  }}
                >
                  {currentData.type === "WELCOME" && (
                    <span
                      style={{
                        fontWeight: 300,
                        fontSize: isLg ? "18px" : "16px",
                        paddingBottom: "10px",
                      }}
                    >
                      Welcome to
                    </span>
                  )}

                  {currentData.title}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    zIndex: 2,
                    display:
                      isSm || isMd || (isLg && !isActiveMenuButtons)
                        ? "flex"
                        : "none",
                  }}
                >
                  Where to next?
                </div>
              </>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                  width: "100%",
                  maxWidth: "320px",
                  marginTop: "10px",
                  gap: "8px",
                }}
              >
                <MenuButtons
                  isActiveMenuButtons={isActiveMenuButtons}
                  setIsActiveMenuButtons={setIsActiveMenuButtons}
                  setIsShownMenu={setIsShownMenu}
                  isLg={isLg}
                  clientData={clientData}
                  bookSelfTour={clientData?.ui.videoWidget.bookSelfTour}
                />
              </div>
            </div>

            {clientData?.ui.chatWidget.isActive && (
              <div
                style={{
                  width: "100%",
                  display: isLg && isActiveMenuButtons ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  paddingRight: "10px",
                }}
              >
                <Button
                  text={"Chat with us!"}
                  isBurgerMenu={isLg && isActiveMenuButtons}
                  first={true}
                  handleClick={() => {
                    navigate("/chat");
                    pushEventToDataLayer(clientData?.id + " chat_opened", {
                      event_category: "button",
                      event_action: "click",
                      event_label: "chat_button",
                      page_path: "/chat",
                      page_client_id: clientData?.id,
                    });
                  }}
                  icon={
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      style={{
                        width: "18px",
                        height: "18px",
                        fill: "#FFFFFF",
                        display: "flex",
                      }}
                    >
                      <path d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM5 8a1 1 0 10-2 0 1 1 0 002 0zm4 0a1 1 0 10-2 0 1 1 0 002 0zm3 1a1 1 0 100-2 1 1 0 000 2z"></path>
                    </svg>
                  }
                  additionalStyling={{ width: "auto", padding: "0 10px" }}
                />
              </div>
            )}

            {/* footer if needed */}
            <div
              style={{
                display: isLg && !isActiveMenuButtons ? "flex" : "none",
                // cursor: "pointer",
                flexDirection: "row",
                alignItems: "center",
                color: "#ffffff",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  pointerEvents: "auto",
                  display: "flex",
                  height: "60px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  transition: "opacity 0.2s ease",
                  borderTop: "1px solid",
                  borderTopColor: "var(--w-tertiary-color)" ?? "#000000",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "300",
                    color: "#000000",
                    whiteSpace: "nowrap",
                    paddingRight: "5px",
                  }}
                >
                  Powered by
                </div>

                <img
                  src={`${process.env.REACT_APP_API_URL}/assets/smp-logo.png`}
                  alt="smp logo"
                  style={{
                    height: "45px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* show main menu on small screens  */}
      <div
        onClick={toggleShowMenu}
        style={{
          background: "rgb(var(--w-secondary-color))" ?? "#ffffff",
          borderTop: !isLg ? "1px solid var(--w-tertiary-color)" : "none",
          display: "flex",
          cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 0.2s ease",
          minHeight: "64px",
          height: "64px",
          zIndex: !isLg ? 50 : -1,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
          style={{
            width: "24px",
            height: "24px",
            color: "#9CA3AF",
          }}
        >
          <path
            fillRule="evenodd"
            d="M1.46447 18.5355C2.92893 20 5.28595 20 10 20c4.714 0 7.0711 0 8.5355-1.4645C20 17.0711 20 14.714 20 10c0-4.71405 0-7.07107-1.4645-8.53553C17.0711 0 14.714 0 10 0 5.28595 0 2.92893 0 1.46447 1.46447 0 2.92893 0 5.28595 0 10c0 4.714 0 7.0711 1.46447 8.5355ZM16.75 14c0 .4142-.3358.75-.75.75H4c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h12c.4142 0 .75.3358.75.75ZM16 10.75c.4142 0 .75-.3358.75-.75 0-.41421-.3358-.75-.75-.75H4c-.41421 0-.75.33579-.75.75 0 .4142.33579.75.75.75h12ZM16.75 6c0 .41421-.3358.75-.75.75H4c-.41421 0-.75-.33579-.75-.75s.33579-.75.75-.75h12c.4142 0 .75.33579.75.75Z"
            clipRule="evenodd"
          ></path>
        </svg>
        <p
          style={{
            ...{
              fontSize: "14px",
              fontWeight: 500,
              color: "#9CA3AF",
              marginLeft: "4px",
              whiteSpace: "nowrap",
            },
            ...paragraphStyle,
          }}
        >
          {isShownMenu ? "Hide Main Menu" : "Show Main Menu"}
        </p>
      </div>
    </div>
  );
};

export default WidgetLayout;
